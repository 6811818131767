<template>
<div class="mainform">
    <div class="form">
        <el-form ref="form" class="content" :model="form" label-width="170px" :disabled="false">
            <el-form-item label="姓名" prop="name" :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone" :rules="{ required: true, message: '请输入联系电话', trigger: 'blur' }">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱" prop="mail" :rules="{ required: true, message: '请输入电子邮箱', trigger: 'blur' }">
                <el-input v-model="form.mail"></el-input>
            </el-form-item>
            <el-form-item label="部门" prop="deptCode" :rules="{ required: true, message: '请选择部门', trigger: 'blur' }">
                <el-select filterable v-model="form.deptCode" placeholder="请选择">
                    <el-option label="销售部" value="销售部"></el-option>
                    <el-option label="采购部" value="采购部"></el-option>
                    <el-option label="财务部" value="财务部"></el-option>
                    <el-option label="品质部" value="品质部"></el-option>
                    <el-option label="产品部" value="产品部"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form.remark"></el-input>
            </el-form-item>
        </el-form>
    </div>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  name: 'AddContacts',
  components: Component.components,
  props: ['formData'],
  data: function () {
    return {
      form: this.formData
    }
  },
  methods: {
    changAction: function (data) {
      if (data.country) {
        this.form.country = data.country
      }
      if (data.province) {
        this.form.province = data.province
      }
      if (data.city) {
        this.form.city = data.city
      }
      if (data.district) {
        this.form.area = data.district
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .mainform{
    background: none;
    padding: 0;
    .form{
      background: none;
      .content{
        padding: 0px 0;
      }
    }
  }
.mainform .form .el-form .el-form-item:last-child {
    margin-bottom: 0px;
}
</style>
